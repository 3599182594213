import { FC, useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { KTIcon } from '../../../_metronic/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';

interface QAInterfaceProps {
    // documentid: string;
    onFirstQuestionAsked: (question: string) => void;
    // onConversationUpdate: (conversation: {}) => void;
    // isNewReport: boolean;
    // setIsNewReport: (isNewReport: boolean) => void;
    currentQuestion: string;
    // conversations: { [key: string]: { qaPairs: QAPair[], domains: string[] } };
}

interface Answer {
    text: string;
    sources: string[];
    valid: boolean;
}

interface QAPair {
    question: string;
    answer: Answer;
    error: boolean;
}

const QAInterface: FC<QAInterfaceProps> = ({ onFirstQuestionAsked, currentQuestion }) => {

    const [question, setQuestion] = useState<string>('');
    const [qaPairs, setQAPairs] = useState<QAPair[]>([]);
    const [firstQuestionAsked, setFirstQuestionAsked] = useState<boolean>(currentQuestion !== '' ? true : false);
    const [sessionDomains, setSessionDomains] = useState<string[]>([]);
    const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
    const [domainMap, setDomainMap] = useState<{ [key: string]: string }>({});
    const [dropdownOpen, setdropdownOpen] = useState(false);
    const toggleDropdown = () => setdropdownOpen(!dropdownOpen);
    //const [selectedDomains, setSelectedDomains] = useState([]);
    const [domainOptions, setDomainOptions] = useState([]);

    const handleChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedDomains(domainOptions); // Select all options
            }
            else {
                setSelectedDomains([]); // Deselect all options
            }
        } else {
            setSelectedDomains((prevSelectedProductCodes) => {
                if (checked && !prevSelectedProductCodes.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedProductCodes, value].length === domainOptions.length;
                    return allSelected ? [...prevSelectedProductCodes, value, 'All'] : [...prevSelectedProductCodes, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedProductCodes.filter((productCode) => productCode !== value && productCode !== 'All');
                }
                return prevSelectedProductCodes;
            });
        }
    };

    const dropdownRef2 = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setdropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    useEffect(() => {
        const fetchDomains = () => {
            axios.get('/reg/v1/user/resources')
                .then(response => {
                    //console.log(response.data);

                    // Filter the response data to include only entries where country is 'USA'
                    const filteredData = response.data.filter(codes => codes.country === 'USA');

                    const options = filteredData.map(codes => `${codes.title}`);
                    setDomainOptions(options);
                    setSelectedDomains(options);

                    const domainMap = filteredData.reduce((acc, curr) => {
                        if (curr.title && curr.link) { // Ensure both title and link are present
                            acc[curr.title] = curr.link;
                        } else {
                            console.warn('Missing title or link in:', curr); // Log any entries missing title or link
                        }
                        return acc;
                    }, {});

                    setDomainMap(domainMap);
                })
                .catch(error => {
                    console.error('Error fetching domains:', error); // Log any errors during the API call
                });
        };
        fetchDomains();
    }, []);

    const clearQuestion = () => {
        setQuestion('');
    }

    useEffect(() => {
        const sessionDomainLinks = Object.keys(domainMap)
            .filter(domain => selectedDomains.includes(domain))
            .map(domain => domainMap[domain]);
        setSessionDomains(sessionDomainLinks);
    }, [selectedDomains, domainMap]);

    useEffect(() => {
        //console.log('Selected Domains:', selectedDomains);
        //console.log('Domains:', sessionDomains);
        //console.log('domain map:', domainMap);
    }, [selectedDomains, sessionDomains, domainMap]);

    const handleDownload = (question, answer) => {
        // Ensure answer is an object for consistent data extraction
        if (typeof answer !== 'object') {
            console.error('Invalid answer format. Expected an object.');
            return;
        }

        // Construct answer content with clear formatting
        let answerContent = `Question: \n${question}\n\nAnswer:\n${answer?.text}\n\n`;

        // Extract and format sources with numbered list
        if (answer.sources && Array.isArray(answer.sources)) {
            answerContent += 'Sources:\n';
            answer.sources.forEach((source, index) => {
                if (typeof source === 'object') {
                    answerContent += `${index + 1}. ${source.title || 'Source'} \n(${source.url || 'No URL'})\n`;
                    answerContent += `Relevant Content: "${source.content || 'No content'}"\n\n`;
                } else {
                    answerContent += `${index + 1}. ${source}\n\n`;
                }
            });
        } else {
            answerContent += 'No sources provided.\n';
        }

        const blob = new Blob([answerContent], { type: 'text/plain' });
        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = fileURL;
        link.download = `${question}_answer.txt`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(link);
    };


    const askQuestion = async () => {
        const newQuestion = question;
        if (!firstQuestionAsked) {
            onFirstQuestionAsked(newQuestion);
            setFirstQuestionAsked(true);
            // setIsNewReport(false);
        }
        setTimeout(async () => {
            setQAPairs(prevQAPairs => [
                {
                    question: newQuestion,
                    answer: { text: 'Thinking ...', sources: [], valid: true },
                    error: false
                },
                ...prevQAPairs
            ]);
        }, 0);
        //setQuestion('');
        try {
            // const response = await fetch(`http://localhost:8000/answer-research-query`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            // });

            // const response = await fetch(`https://deepapi.lexim.ai/answer-research-query`, {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            // });

            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/answer-research-query/tavily`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: { query: newQuestion }, domains: sessionDomains })
            });

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    // Once the stream is fully read, parse the JSON
                    const jsonResponse = JSON.parse(text);
                    setQAPairs(prevQAPairs => {
                        const newQAPairs = [...prevQAPairs];

                        // Ensure newQAPairs[0] exists
                        newQAPairs[0] = { question: newQuestion, answer: { text: 'Thinking ...', sources: [], valid: true }, error: false };

                        // Safely assign values to answer
                        newQAPairs[0].question = newQuestion;
                        newQAPairs[0].answer.text = jsonResponse.answer || "No answer provided.";
                        newQAPairs[0].answer.sources = jsonResponse.results || [];

                        return newQAPairs;
                    });
                    return;
                }
                text += new TextDecoder().decode(value);
                await readStream();
            };

            await readStream();

        } catch (error) {
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs];
                newQAPairs[0].question = newQuestion;
                newQAPairs[0].answer['text'] = (error as Error).message;
                newQAPairs[0].answer['sources'] = [];
                newQAPairs[0].error = true;
                return newQAPairs;
            });
        }
    };

    // useEffect(() => {
    //     if (firstQuestionAsked) {
    //         const updatedConversations = { ...conversations }; // Deep copy

    //         if (updatedConversations[currentQuestion]) {
    //             // Existing report: update existing entry
    //             updatedConversations[currentQuestion] = {
    //                 ...updatedConversations[currentQuestion],
    //                 qaPairs: [...qaPairs],
    //                 domains: [...sessionDomains]
    //             };
    //         } else {
    //             // New report: create a new entry
    //             updatedConversations[currentQuestion] = {
    //                 qaPairs: [...qaPairs],
    //                 domains: [...sessionDomains]
    //             };
    //         }

    //         onConversationUpdate(updatedConversations);
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentQuestion, qaPairs, firstQuestionAsked]);

    // useEffect(() => {
    //     // console.log('useEffect triggered with currentQuestion:', currentQuestion);
    //     // console.log(conversations[currentQuestion]);
    //     setQAPairs(conversations[currentQuestion]?.qaPairs || []);
    //     if (conversations[currentQuestion]) {
    //         setSessionDomains(conversations[currentQuestion]?.domains || []);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentQuestion]);

    // useEffect(() => {
    //     if (isNewReport) {
    //         setQuestion('');
    //         //setQAPairs([]);
    //         setFirstQuestionAsked(false);
    //         setSessionDomains([]);
    //     }
    // }, [isNewReport, setIsNewReport]);

    return (
        <div className={`card-body`} style={{ maxHeight: 'calc(100vh)', flex: 1 }}>
            <div className='d-flex align-items-center justify-content-center'>
                <div className='d-flex position-relative' style={{ flex: 1 }}>
                    <input
                        type="text"
                        className="form-control pe-16 text-dark"
                        placeholder={firstQuestionAsked ? "Please ask a follow-up question" : "Please ask a research question"}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                askQuestion();
                                e.preventDefault(); // Prevent form submission
                            }
                        }}
                        style={{ paddingRight: '50px' }} // Make room for the icon
                    />
                    {question !== '' && (
                        <div>
                            <button onClick={clearQuestion} className='position-absolute end-0 top-50 translate-middle-y form-control-button align-items-center justify-content-center' style={{ background: 'none', border: 'none' }}>
                                <KTIcon
                                    iconName='cross'
                                    iconType="duotone"
                                    className='text-dark fs-1 mx-2 mt-1 me-15 icon-color'
                                />
                            </button>
                            <div style={{ borderLeft: '1px solid #d3d3d3', height: '24px', position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '45px' }}></div>
                        </div>
                    )}
                    <button onClick={askQuestion} className='position-absolute end-0 top-50 translate-middle-y form-control-button align-items-center justify-content-center' style={{ background: 'none', border: 'none' }}>
                        <KTIcon
                            iconName='arrow-right'
                            iconType="duotone"
                            className='text-dark fs-1 mx-2 mt-1 me-4 icon-color'
                        />
                    </button>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='dropdown ms-7' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Domains to Search
                        </button>
                        {dropdownOpen && (
                            <ul
                                className='dropdown-menu show mt-1'
                                aria-labelledby='dropdownSpecialityButton'
                                style={{
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                    width: window.innerWidth > 576 ? '300%' : '250%',
                                    left: window.innerWidth > 576 ? '-200%' : '-150%',
                                    paddingLeft: '5px',
                                    paddingRight: '35px',
                                    paddingTop: '15px',

                                }}
                            >
                                <li style={{ margin: '10px 0' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedDomains.length === domainOptions.length} onChange={handleChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {domainOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className='form-check-input me-2 mb-2 ms-5'
                                            type='checkbox'
                                            id={`speciality${index}`}
                                            name='specialityOption'
                                            value={option}
                                            checked={selectedDomains.includes(option)}
                                            onChange={handleChange}
                                        />
                                        <label
                                            className='form-label fw-bold ms-5 mb-2'
                                            htmlFor={`speciality${index}`}
                                            style={{ fontSize: '1.1rem', flex: '1' }}
                                        >
                                            {option}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
                {/* <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip">Download full chat</Tooltip>}
                >
                    <Button className="btn btn-sm btn-primary d-flex align-items-center justify-content-center mx-4">
                        <i className="bi bi-download fs-1 ms-1"></i>
                    </Button>
                </OverlayTrigger> */}
            </div>
            <div className='mb-5 mt-2' style={{ maxHeight: 'calc(80vh)', overflowY: 'auto' }}>

                {qaPairs.map((qaPair, index) => (
                    <div key={index} className='d-flex flex-column mt-3'>
                        <div className='d-flex justify-content-start mt-2 mb-2'>
                            <div className={`p-7 ps-11 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-primary '}`} style={{ width: '100%', wordWrap: 'break-word' }}>
                                {qaPair.answer['text'] !== 'Thinking ...' && (
                                    <div className="d-flex justify-content-end">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip id="button-tooltip">Download message</Tooltip>}
                                        >
                                            <i
                                                className="bi bi-download text-primary text-bold fs-1 me-1"
                                                onClick={() => handleDownload(qaPair.question, qaPair.answer)}
                                            ></i>
                                        </OverlayTrigger>
                                    </div>
                                )}
                                <h1 className='text-dark fw-bold mb-5' style={{ fontSize: '1.5rem' }}>{qaPair.question}</h1>
                                {qaPair.answer['text'] === 'Thinking ...' ?
                                    <>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </>
                                    :
                                    <div>
                                        <h3 className='fw-bold mb-3 text-dark'>Answer:</h3>
                                        <ReactMarkdown className="mb-5">{qaPair.answer.text}</ReactMarkdown>
                                        <h3 className='fw-bold mb-3 text-dark'>Sources:</h3>
                                        <ReactMarkdown>
                                            {qaPair.answer.sources.length >= 1 ? qaPair.answer.sources.map(source => `* **${source['title']}**\n\n\t (*${source['url']}*)`).join('\n\n') : 'No sources provided.'}
                                        </ReactMarkdown>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export { QAInterface };
