/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { SidebarMenuItem } from './SidebarMenuItem'
// import { useAuth } from '../../../../../app/modules/auth'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const SidebarMenuMain = () => {
  // const { currentUser } = useAuth()

  return (
    <>
      <SidebarMenuItem to='/HOME' icon='home' title='HOME' fontIcon='home' />
      <div style={{ width: '100%', height: '1px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div>
      <SidebarMenuItemWithSub
        to=''
        title='REGULATORY'
        fontIcon='category'
        icon='category'
      >
        <SidebarMenuItem to='/preamble' icon='folder' title='FDA PREAMBLE' fontIcon='folder' />
        <SidebarMenuItem to='/cor/title-21' icon='folder' title='CFR TITLE 21' fontIcon='folder' />
        <SidebarMenuItem to='/calendar' icon='calendar' title='CALENDAR' fontIcon='calendar' />
        {/* <SidebarMenuItem to='/docket' icon='folder' title='Dockets' fontIcon='folder' /> */}
        <SidebarMenuItem to='/proposed-rules' icon='document' title='PROPOSED RULES' fontIcon='document' />
        <SidebarMenuItem to='/final-rules' icon='file-added' title='FINAL RULES' fontIcon='file-added' />
        <SidebarMenuItem to='/guidances' icon='folder' title='GUIDANCE' fontIcon='folder' />
        <SidebarMenuItem to='/notices' icon='document' title='NOTICES' fontIcon='document' />
        <SidebarMenuItem to='/debarment-list' icon='message-text-2' title='DEBARMENT' fontIcon='message-text-2' />
        <SidebarMenuItem to='/warning-letters' icon='document' title='WARNING LETTERS' fontIcon='document' />
        {/* <SidebarMenuItem to='/links' icon='capsule' title='USEFUL LINKS' fontIcon='capsule' />
        <SidebarMenuItemWithSub
          to=''
          title='CODE OF REGULATIONS'
          fontIcon='folder'
          icon='folder'
        >
          <SidebarMenuItem to='/title-21' icon='folder' title='Title21' fontIcon='folder' />
          <SidebarMenuItem to='/title-42' icon='folder' title='Title42' fontIcon='folder' />
        </SidebarMenuItemWithSub> */}
        {/* <SidebarMenuItem to='/comments' icon='message-text-2' title='Comments' fontIcon='message-text-2' /> */}
      </SidebarMenuItemWithSub>
      <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div>
      <SidebarMenuItem to='/myresearch' icon='search-list' title='MY RESEARCH' fontIcon='search-list' />
      <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div>
      <SidebarMenuItem to='/apps/mydocuments' icon='file-up' title='MY DOCUMENTS' fontIcon='home' />
      {/* <SidebarMenuItem to='/insights' icon='chart' title='REPORTS' fontIcon='chart' /> */}
      <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div>
      <SidebarMenuItemWithSub
        to=''
        title='DEVICE INTELLIGENCE'
        fontIcon='category'
        icon='category'
      >
        {/* <SidebarMenuItem to='/device-intelligence/home' icon='home-2' title='HOME' fontIcon='home-2' /> */}
        <SidebarMenuItem to='/device-intelligence/registration' icon='devices' title='REGISTRATIONS' fontIcon='devices' />
        <SidebarMenuItem to='/device-intelligence/classification' icon='devices' title='CLASSIFICATIONS' fontIcon='devices' />
        <SidebarMenuItem to='/device-intelligence/device' icon='devices' title='510(K) CLEARANCES' fontIcon='devices' />
        <SidebarMenuItem to='/device-intelligence/pma' icon='devices' title='PREMARKET APPROVALS' fontIcon='devices' />
        <SidebarMenuItem to='/device-intelligence/adverseevents' icon='calendar-remove' title='MAUDE (MDRs)' fontIcon='kanban' />
        <SidebarMenuItem to='/device-intelligence/recalls' icon='parcel-tracking' title='RECALLS' fontIcon='loading' />
        {/* <div style={{ paddingLeft: '15px' }}><SidebarMenuItem to='/device-intelligence/enforcements' icon='shield-search' title='A. ENFORCEMENT' fontIcon='loading' /></div> */}

        {/* <SidebarMenuItem to='/pmcomingsoon' icon='message-text-2' title='POST-MARKET' fontIcon='message-text-2' /> */}
      </SidebarMenuItemWithSub >
      <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div>

      {/* <SidebarMenuItemWithSub
        to=''
        title='LEGISLATIVE'
        icon='category'
        fontIcon='category'
      > */}
      {/* <SidebarMenuItem to='/house' icon='book-square' title='AFFORDABLE CARE ACT' fontIcon='book-square' />
        <SidebarMenuItem to='/senate' icon='home-2' title='SUNSHINE ACT' fontIcon='home-2' />
        <SidebarMenuItem to='/state' icon='geolocation-home' title='INFLATION REDUCTION ACT' fontIcon='bi-app-indicator' /> */}
      {/* <SidebarMenuItem to='/comingsoon' icon='book-square' title='AFFORDABLE CARE ACT' fontIcon='book-square' />
        <SidebarMenuItem to='/comingsoon' icon='home-2' title='SUNSHINE ACT' fontIcon='home-2' />
        <SidebarMenuItem to='/comingsoon' icon='geolocation-home' title='INFLATION REDUCTION ACT' fontIcon='bi-app-indicator' /> */}
      {/* </SidebarMenuItemWithSub> */}
      {/* <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div> */}
      {/* <SidebarMenuItemWithSub
        to=''
        title='TOOLS'
        fontIcon='wrench'
        icon='wrench'
      >
        <SidebarMenuItem to='/mydocuments' icon='file-up' title='MY VAULT' fontIcon='file-up' />
        <SidebarMenuItem to='/translation' icon='geolocation' title='TRANSLATE' fontIcon='translate' />
        <SidebarMenuItem to='/comingsoon' icon='file' title='DOC DIFF' fontIcon='file' /> */}
      {/* <SidebarMenuItem to='/comingsoon' icon='filter-search' title='SPL(LABEL) SEARCH' fontIcon='filter-search' /> */}
      {/* <SidebarMenuItem to='/comingsoon' icon='code' title='PLANNING' fontIcon='code' /> */}
      {/* </SidebarMenuItemWithSub> */}
      {/* <div style={{ width: '100%', height: '0.5px', backgroundColor: '#4d4d4d', margin: 'auto' }}></div> */}
      {/* <SidebarMenuItem to='crafted/account/' icon='profile-circle' title='PROFILE' fontIcon='home' /> */}

      {/* <SidebarMenuItemWithSub
        to=''
        title='MY VAULT'
        fontIcon='category'
        icon='category'
      > */}
      {/* {Object.values(vaultData).map((vault) => (
          <SidebarMenuItemWithSub to='' icon='folder' key={vault.vault_id} title={vault.name.toUpperCase()} >
            {vault.sub_vaults.map((subVault) => (
              <SidebarMenuItem to={`collection/${subVault.vault_id}`} key={subVault.vault_id} title={subVault.name.toUpperCase()} hasBullet state={subVault} />
            ))}
          </SidebarMenuItemWithSub>
        ))} */}
      {/* </SidebarMenuItemWithSub> */}


      <SidebarMenuItem to='/account/support' icon='setting' title='SUPPORT' fontIcon='home' />
      {/* {(currentUser?.role === 2 || currentUser?.role === 3) && (
        <SidebarMenuItem
          to='/admin'
          icon='profile-user'
          title='ADMIN'
          fontIcon='profile-user'
        />
      )}
      {currentUser?.role === 3 && (
        <SidebarMenuItem
          to='/super-admin'
          icon='user'
          title='SUPER ADMIN'
          fontIcon='user'
        />
      )} */}
    </>
  )
}

export { SidebarMenuMain }