import React, { useState, useEffect, useCallback, useRef } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import CalendarFilter from './CalendarFilter';
import { useSelector } from 'react-redux';
import { CalendarListPagination } from './CalendarListPagination';
import moment from 'moment';
import 'moment-timezone';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Fuse from 'fuse.js';
// import PreferencesMenu from './PreferencesMenu';

const CalendarCards = () => {
    // Define docket    
    const navigate = useNavigate();
    const { logout } = useAuth();
    const [events, setEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    const [totalPages, setTotalPages] = useState(0);
    const [programs, setPrograms] = useState({});
    const prevSearchInputRef = useRef();

    const [filters, setFilters] = useState({
        agency: '',
        documentid: '',
        documenttitle: '',
        program: '',
        startDate: '',
        endDate: '',
    });
    const interests = useSelector(state => state.account.interests);
    const [sortOrder, setSortOrder] = useState('asc'); // New state variable for sort order

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [currentPage]);

    useEffect(() => {
        if (interests) {
            const newPrograms = {};
            interests.programs.forEach(program => {
                const match = program ? program.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newPrograms[key] = value;
                }
            });
            setPrograms(newPrograms);
        }
    }, [interests]);

    const formatDate = useCallback((timestamp) => {
        if (timestamp === null) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, []);

    const formatDate2 = useCallback((timestamp) => {
        if (isNaN(Date.parse(timestamp))) {
            return null;
        }
        const date = moment.utc(timestamp); // Use UTC
        return date.format('YYYY-MM-DD'); // Format in UTC
    }, []);



    const fetchAndProcessEvents = useCallback(() => {
        // Attempt to load events from localStorage first
        const storedEvents = localStorage.getItem('events');
        if (storedEvents) {
            const parsedEvents = JSON.parse(storedEvents);
            setEvents(parsedEvents);
            setFilteredEvents(parsedEvents);
            setLoading(false);
        } else {
            // If no events in localStorage, fetch from API
            setLoading(true);
            axios.get('reg/v1/calendar/active_comments')
                .then((response) => {
                    let sortedEvents = response.data.sort((a, b) => a.commentEndDate - b.commentEndDate);
                    const newPrograms = interests.programs.map(program => {
                        const match = program ? program.match(/\((.*?)\)/) : undefined;
                        return match && match.length > 1 ? match[1] : program;
                    });
                    if (interests.agencies.length > 0 || newPrograms.length > 0) {
                        sortedEvents = sortedEvents.filter(event =>
                            (interests.agencies.length === 0 || interests.agencies.includes(event.agencyId)) &&
                            (newPrograms.length === 0 || newPrograms.includes(event.program))
                        );
                    }
                    let today = new Date();
                    today.setHours(0, 0, 0, 0);
                    sortedEvents = sortedEvents.filter(event => {
                        let commentEndDate = new Date(formatDate(event.commentEndDate));
                        return commentEndDate >= today;
                    });
                    setEvents(sortedEvents);
                    setFilteredEvents(sortedEvents);
                    setLoading(false);
                    // Store the fetched events in localStorage
                    try {
                        localStorage.setItem('events', JSON.stringify(sortedEvents));
                    } catch (e) {
                        if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
                            console.error("LocalStorage quota exceeded.");
                            // Optionally, implement fallback storage or notify the user
                        } else {
                            throw e; // Re-throw the error if it's not a QuotaExceededError
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response && error.response.status === 401) {
                        logout();
                        navigate('/auth/login');
                    } else {
                        console.log('Error fetching active comments:', error);
                        setEvents([]);
                        setFilteredEvents([]);
                    }
                });
        }
    }, [interests.agencies, interests.programs, formatDate, logout, navigate, setEvents, setFilteredEvents, setLoading]);

    useEffect(() => {
        fetchAndProcessEvents();
    }, [fetchAndProcessEvents]);
    // useEffect(() => {
    //     setLoading(true);
    //     axios.get('reg/v1/calendar/active_comments')
    //         .then((response) => {
    //             let sortedEvents = response.data.sort((a, b) => a.commentEndDate - b.commentEndDate);
    //             const newPrograms = interests.programs.map(program => {
    //                 const match = program ? program.match(/\((.*?)\)/) : undefined;
    //                 return match && match.length > 1 ? match[1] : program;
    //             });
    //             if (interests.agencies.length > 0 || newPrograms.length > 0) {
    //                 sortedEvents = sortedEvents.filter(event =>
    //                     (interests.agencies.length === 0 || interests.agencies.includes(event.agencyId)) &&
    //                     (newPrograms.length === 0 || newPrograms.includes(event.program))
    //                 );
    //             }
    //             let today = new Date();
    //             today.setHours(0, 0, 0, 0);
    //             sortedEvents = sortedEvents.filter(event => {
    //                 let commentEndDate = new Date(formatDate(event.commentEndDate));
    //                 return commentEndDate >= today;
    //             });
    //             setEvents(sortedEvents);
    //             setFilteredEvents(sortedEvents);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //             if (error.response.status === 401) {
    //                 logout();
    //                 navigate('/auth/login');
    //             } else {
    //                 console.log('Error fetching active comments:', error);
    //                 setEvents([]);

    //                 setFilteredEvents([]); 

    //             }

    //         });

    // }, [logout, navigate, interests.agencies, interests.programs, programs, formatDate]);


    useEffect(() => {
        prevSearchInputRef.current = searchInput;
    }, [searchInput]);

    const debounceDelay = searchInput.trim() === '' ? 0 : (prevSearchInputRef.current === searchInput ? 0 : 3000);

    useEffect(() => {
        if (events?.length > 0) {
            const delayDebounceFn = setTimeout(() => {
                if (searchInput.trim() === '') {
                    setFilteredEvents(events);
                } else {

                    const options = {
                        includeScore: true,
                        // Add other options based on your requirements
                        keys: [{
                            name: 'id',
                            weight: 0.5,
                            getFn: (item, path) => {
                                // Normalize by removing hyphens and converting to lowercase for comparison
                                const normalizedItemId = item.id.replace(/-/g, '').toLowerCase();
                                const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
                                if (normalizedItemId.includes(normalizedSearchInput)) {
                                    return [item.id]; // Return the 'id' in an array if it matches after normalization
                                }
                                return []; // Return an empty array if not a match
                            }
                        }, {
                            name: 'docketId',
                            weight: 0.4,
                            getFn: (item, path) => {
                                // Normalize by removing hyphens and converting to lowercase for comparison
                                const normalizedItemId = item.docketId.replace(/-/g, '').toLowerCase();
                                const normalizedSearchInput = searchInput.trim().replace(/-/g, '').toLowerCase();
                                if (normalizedItemId.includes(normalizedSearchInput)) {
                                    return [item.docketId]; // Return the 'id' in an array if it matches after normalization
                                }
                                return []; // Return an empty array if not a match
                            }



                        }, {
                            name: 'title',
                            weight: 0.3 // Added weight for document_title
                        }, 'subtype', 'documentType', 'program', 'dk_Abstract', 'topics', 'docAbstract',
                        {
                            name: 'dateFields',
                            weight: 0.1,
                            getFn: (item, path) => {
                                // Assuming formatDate and formatDate2 return dates in 'YYYY-MM-DD' format
                                let commentEndDateET = formatDate(item.commentEndDate);
                                let commentStartDateET = formatDate(item.commentStartDate);
                                let postedDate = formatDate2(item.postedDate);

                                // Assuming searchInput is the date in 'YYYY-MM-DD' format
                                const normalizedSearchInput = searchInput.trim();

                                let matches = [];
                                // Check if any of the date fields include the search input and add them to matches array
                                if (commentStartDateET?.includes(normalizedSearchInput)) {
                                    matches.push(commentStartDateET);
                                }
                                if (commentEndDateET?.includes(normalizedSearchInput)) {
                                    matches.push(commentEndDateET);
                                }
                                if (postedDate?.includes(normalizedSearchInput)) {
                                    matches.push(postedDate);
                                }

                                return matches; // Return an array of matching date strings
                            }
                        }
                        ],
                        ignoreLocation: true, // This helps in making the search fuzzier
                        threshold: 0.3, // Adjust this threshold to make the search stricter or fuzzier
                    };

                    // Create a new instance of Fuse with the documents and options
                    const fuse = new Fuse(events, options);

                    // Perform the search
                    const result = fuse.search(searchInput.trim());

                    // Map the result to get the original documents
                    const filteredDocuments = result.map(resultItem => resultItem.item);

                    setFilteredEvents(filteredDocuments);


                }
            }, debounceDelay) // delay in ms

            return () => {
                setLoading(true);
                clearTimeout(delayDebounceFn);
                setLoading(false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, events, formatDate, formatDate2]);


    const handleFilterChange = (key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));

    };
    const handleApplyFilters = () => {
        setLoading(true);
        // console.log(filters)
        setTimeout(() => {
            let filtered = events.filter((event) =>
                (!filters.agency || (event.agencyId && event.agencyId.toLowerCase().includes(filters.agency.toLowerCase()))) &&
                (!filters.program || (event.program && event.program.toLowerCase().includes(filters.program.toLowerCase()))) &&
                (!filters.documentid || (event.id && event.id.toLowerCase().includes(filters.documentid.toLowerCase()))) &&
                (!filters.documenttitle || (event.title && event.title.toLowerCase().includes(filters.documenttitle.toLowerCase()))) &&
                (!filters.startDate || new Date(event.postedDate).getTime() >= new Date(filters.startDate).getTime()) &&
                (!filters.endDate || new Date(event.commentEndDate).getTime() >= new Date(filters.endDate).getTime()) &&
                (!filters.interests || interests.agencies.includes(event.agencyId))
            );
            // setFilteredEvents(filtered);
            // setLoading(false);
            setCurrentPage(1);

            // Slice the array and set it to filteredEvents
            setFilteredEvents(filtered);
            setLoading(false);
        }, 500); // delay of 2 seconds
    };

    const handleResetFilters = () => {
        setLoading(true);
        // Reset the filters state
        setFilters({
            agency: '',
            documentid: '',
            documenttitle: '',
            program: '',
            startDate: '',
            endDate: '',
        });
        // Reset the filtered dockets
        setCurrentPage(1);

        setFilteredEvents(events);
        setLoading(false);

    };
    const toggleSortOrder = () => {
        let newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        let sortedEvents = [...filteredEvents];
        sortedEvents.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return new Date(a.commentEndDate) - new Date(b.commentEndDate);
            } else {
                return new Date(b.commentEndDate) - new Date(a.commentEndDate);
            }
        });
        setFilteredEvents(sortedEvents);
    };

    useEffect(() => {
        setTotalPages(Math.ceil(filteredEvents?.length / Math.max(1, itemsPerPage)));
        if (currentPage > totalPages) {
            setCurrentPage(1);
        }
    }, [filteredEvents, currentPage, totalPages, itemsPerPage]);

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        let filtered = events.filter((event) =>
            (!filters.agency || (event.agencyId && event.agencyId.toLowerCase().includes(filters.agency.toLowerCase()))) &&
            (!filters.program || (event.program && event.program.toLowerCase().includes(filters.program.toLowerCase()))) &&
            (!filters.documentid || (event.id && event.id.toLowerCase().includes(filters.documentid.toLowerCase()))) &&
            (!filters.documenttitle || (event.title && event.title.toLowerCase().includes(filters.documenttitle.toLowerCase()))) &&
            (!filters.startDate || new Date(event.postedDate).getTime() >= new Date(filters.startDate).getTime()) &&
            (!filters.endDate || new Date(event.commentEndDate).getTime() >= new Date(filters.endDate).getTime()) &&
            (!filters.interests || interests.agencies.includes(event.agencyId))
        );
        setFilteredEvents(filtered);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);


    // const totalPages = Math.ceil(filteredEvents.length / EVENTS_PER_PAGE);



    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='calendar' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary  ' />
                                <span>Calendar - Document Events</span>
                                {loading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                </div>
                {interests.agencies && programs &&
                    <div className="d-flex flex-row-fluid px-10">
                        {interests.agencies.length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className="d-md-block text-wrap mt-1">Agencies: {interests.agencies.join(', ')}</h6>
                                {Object.keys(programs).length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {Object.keys(programs).length > 0 && (
                            <div style={{ paddingLeft: interests.agencies.length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-md-block text-wrap mt-1`}>Programs: {' '}
                                    {Object.entries(programs).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                            </div>
                        )}
                    </div>
                }
                <div className="d-flex flex-column px-10 align-items-start">
                    {(filters.documenttitle || filters.agency || filters.documentid || filters.program || filters.startDate || filters.endDate) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filters.documenttitle && <li><h6 className="d-md-block text-wrap">Document Title: {filters.documenttitle}</h6></li>}
                                {filters.documentid && <li><h6 className="d-md-block text-wrap">Document ID: {filters.documentid}</h6></li>}
                                {filters.agency && <li><h6 className="d-md-block text-wrap">Agency: {filters.agency}</h6></li>}
                                {filters.program && <li><h6 className="d-md-block text-wrap">Program: {filters.program}</h6></li>}
                                {filters.startDate && <li><h6 className="d-md-block text-wrap">Posted Date (On/After): {filters.startDate}</h6></li>}
                                {filters.endDate && <li><h6 className="d-md-block text-wrap">Comments Due Date (On/After): {filters.endDate}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div>
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                    <div className="col-7">
                        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                    </div>
                    <div className="d-flex flex-column-auto d-flex align-items-center">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light  d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <CalendarFilter
                            filters={filters}
                            onFilterChange={handleFilterChange}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}

                        />
                    </div>

                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                        onClick={toggleSortOrder}
                    >
                        <KTIcon iconName={sortOrder === 'desc' ? 'arrow-down' : 'arrow-up'} iconType="solid" className='fs-2' /> Sort
                    </button>
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                        <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid px-3 pt-2 mb-4">

                    {filteredEvents.length > 0 ? (
                        filteredEvents.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage)).map((event, index) => (

                            <div className="col-md-12" key={index}>
                                <div className="card card-custom card-border  m-2" key={index} style={{ backgroundColor: '', border: '1px solid #D6D7D9' }}>

                                    <div className="card-body ">

                                        <h5 className="card-title text-muted pb-2" style={{ textTransform: 'uppercase' }} >{event.documentType}</h5>
                                        <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}> Comments due: {formatDate(event.commentEndDate)}</h5>

                                        <h5 className="card-text pb-2" style={{ color: '#0077b3' }}>{event.title}</h5>
                                        <div className="d-flex align-items-center">
                                            <h5 className="card-title text-gray-700 pb-2">Agency: <span className="text-gray-600">{event.agencyId}</span></h5>
                                            <span className="card-title text-gray-700 pb-2 px-2">|</span>

                                            <h5 className="card-title text-gray-700 pb-2">Program: <span className="text-gray-600">{event.program}</span></h5>
                                            <span className="card-title text-gray-700 pb-2 px-2">|</span>

                                            <h5 className="card-title pb-2 text-gray-700">Document ID: <span className="text-gray-600">{event.id}</span></h5>
                                            <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                            <h5 className="card-title text-gray-700 pb-2">Docket ID: <a href={`https://www.regulations.gov/docket/${event.docketId}`} target="_blank" rel="noopener noreferrer"><span className="text-primary-600">{event.docketId}</span> </a></h5>
                                            <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                            <h5 className="card-title text-gray-700 pb-2">Posted: <span className="text-gray-600">{formatDate2(event.postedDate)}</span></h5>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (Object.values(filters).some(value => value !== '') && !loading) ? (
                        <h3 className="d-md-block text-wrap mt-2 me-5" style={{ color: "#4d4d4d", marginLeft: '15px', marginTop: '20px' }}>No events found with the selected filters.</h3>
                    ) : null}

                </div>
            </div>
            {!loading && (
                <div className="d-flex justify-content-center align-items-center">

                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-7">
                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>
                    <CalendarListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            )}
        </div>

    );
};

export default CalendarCards;